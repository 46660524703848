export enum GameHouse {
  WARRIOR_MALE = "WARRIOR_MALE",
  WARRIOR_FEMALE = "WARRIOR_FEMALE",
  ROBOT = "ROBOT",
  WITCH_MALE = "WITCH_MALE",
  WITCH_FEMALE = "WITCH_FEMALE",
  VAMPIRE = "VAMPIRE",
  NOBLEMAN_MALE = "NOBLEMAN_MALE",
  NOBLEMAN_FEMALE = "NOBLEMAN_FEMALE",
}
