import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { animation } from "./animation.helper";
import { Draw, SingleAnimationComponentProps } from "./animation.interface";
import "./combine-animation.module.css";
export type DrawInfo = Omit<
  SingleAnimationComponentProps,
  "sizeRatio" | "frameTime" | "sizeRatio"
> & {
  sizeRatio: Draw["sizeRatio"];
  location: Draw["location"];
};

export type CombineAnimationComponentRef = {
  forceRender: (
    newDrawInformations?: Array<DrawInfo>,
    stage?: "ATTACK" | "NORMAL",
  ) => void;
};

const CombineAnimationComponent = forwardRef(
  (
    {
      drawInformations,
      width,
      height,
      frameTime,
      onLastFrame,
    }: {
      drawInformations: Array<DrawInfo>;
      width: number;
      height: number;
      frameTime: number;
      onLastFrame?: () => void;
    },
    ref: any,
  ) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasContext = useRef<CanvasRenderingContext2D>();
    const intervalRef = useRef<NodeJS.Timer>();
    const currentDrawInformations = useRef<
      Array<{
        fnc: (draw: Draw) => void;
        params: Partial<DrawInfo>;
      }>
    >();

    useEffect(() => {
      canvasContext.current = canvasRef.current!.getContext("2d")!;
    }, [canvasRef]);

    useEffect(() => {
      currentDrawInformations.current = drawInformations.map((drawInfo) => {
        const drawFunc = animation({
          ...drawInfo,
          frameTime,
        });
        return {
          fnc: drawFunc,
          params: drawInfo,
        };
      });
      drawing();
      return () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
      };
    }, []);

    const drawing = (cb?: () => void) => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        canvasContext.current?.clearRect(0, 0, width, height);
        if (!currentDrawInformations.current?.length) return;

        currentDrawInformations.current.forEach((drawFunc) => {
          drawFunc.fnc({ ctx: canvasContext.current!, ...drawFunc.params });
        });
        cb && cb();
      }, frameTime);
    };

    useImperativeHandle(ref, () => ({
      forceRender: (
        newDrawInformations?: Array<DrawInfo>,
        stage?: "ATTACK" | "NORMAL",
      ) => {
        if (
          !currentDrawInformations.current?.length ||
          !newDrawInformations?.length
        )
          return;

        for (
          let index = 0;
          index < currentDrawInformations.current.length;
          index++
        ) {
          const newDraw = newDrawInformations[index];
          currentDrawInformations.current[index] = {
            fnc: animation({
              ...newDraw,
              frameTime,
            }),
            params: {
              ...currentDrawInformations.current[index].params,
              ...newDrawInformations,
            },
          };
        }
        let hasDrawed = false;
        const call = () => {
          if (hasDrawed || stage !== "ATTACK") return;
          hasDrawed = true;
          onLastFrame && onLastFrame();
        };
        drawing(call);
      },
    }));

    return <canvas ref={canvasRef} width={width} height={height} />;
  },
);

export default CombineAnimationComponent;
