import styles from "./inventory-item.module.css";

interface Item {
  inventoryId: string;
  name?: string;
  itemType?: string;
  image: string;
  isEmpty?: boolean;
}

interface InventoryItemProps {
  onClick?: (item: Item) => void;
  item: Item;
}

const InventoryItem = ({ item, onClick }: InventoryItemProps) => {
  return (
    <button onClick={() => onClick && onClick(item)} className={styles.button}>
      <img
        src={item?.image}
        alt={item?.itemType}
        className={`${item?.isEmpty && styles.emptyImage}`}
      />
    </button>
  );
};

export default InventoryItem;
