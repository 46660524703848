import { useNavigate } from "react-router-dom";
import { images } from "../../constants";
import styles from "./header.module.css";
import { useAppSelector } from "../../modules/redux/hook";
import { useEffect, useMemo, useRef, useState } from "react";
import { dateDiff } from "../../modules/utils/helper";

interface HeaderProps {
  isShowChampion?: boolean;
}
const Header = ({ isShowChampion = true }: HeaderProps) => {
  const app = useAppSelector(({ app }) => app);
  const { gameProfile } = useAppSelector(({ app }) => app);
  const [counter, setCounter] = useState("");
  const timer = useRef<NodeJS.Timer>();
  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimer();
      }
    };
  }, []);

  useEffect(() => {
    if (!gameProfile!.currentGameProfileSeason) {
      clearTimer();
      return;
    }
    timer.current = setInterval(() => {
      const timer = dateDiff(
        gameProfile!.currentGameProfileSeason.lastRechargeEnergyAt,
      );
      setCounter(timer!);
      if (!timer) return clearTimer();
    }, 1000);
  }, [gameProfile!.currentGameProfileSeason]);
  const clearTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };
  const enegy = useMemo(() => {
    if (!gameProfile) return;

    return (
      <>
        <div className={styles.enegy}>
          <img src={images.enegy} alt='enegy' />
          <p>
            {gameProfile?.currentGameProfileSeason?.energy || 0}/{"000000"}
          </p>
        </div>
        {counter && (
          <div className={styles.counter}>
            <p>Recover 5 energy in {counter}</p>
          </div>
        )}
      </>
    );
  }, [gameProfile?.currentGameProfileSeason, counter]);

  const rank = useMemo(() => {
    return (
      <div className={styles.champion} onClick={() => navigate("/season")}>
        <div className={styles.championImage}>
          <img
            src='https://staggering.tonkombat.com/assets/champion-D65sUMQF.webp'
            alt='Rank'
          />
        </div>
        <div className={styles.championInfo}>
          <p>{gameProfile?.currentGameProfileSeason.rankPoint || 0}</p>
          <img
            src='https://staggering.tonkombat.com/assets/animate_moonbix-CTtSSwtb.gif'
            alt='amimate moonbix'
          />
        </div>
      </div>
    );
  }, [gameProfile?.currentGameProfileSeason, isShowChampion]);

  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.headerLeft}>
        <div className={styles.house}>
          <div className={styles.hero}>
            <img
              onClick={() => {
                navigate("/stats");
              }}
              src={images.hero.helmet}
              alt='Hero'
            />
          </div>
          <div className={styles.season} onClick={() => navigate("/profile")}>
            <img
              src='https://staggering.tonkombat.com/assets/hamster-medal-DTGO-9Rp.webp'
              alt='Medal'
            />
            <img
              src='https://staggering.tonkombat.com/assets/mythic-PaCcRX2O.webp'
              alt='last season rank'
            />
          </div>
        </div>
        {rank}
      </div>
      <div className={styles.headerRight}>{enegy}</div>
    </div>
  );
};

export default Header;
