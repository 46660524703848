import { GameHouse } from "../enums";
import { HouseCharacter } from "../interfaces";

export const HOUSE_CONFIGURATIONS: Record<GameHouse, HouseCharacter> = {
  WARRIOR_MALE: {
    code: "WARRIOR_MALE",
    house: "WARRIOR",
    gender: "MALE",
    images: {
      main: {
        url: "/assets/characters/warrior-male/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/characters/warrior-male/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/characters/warrior-male/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
  WARRIOR_FEMALE: {
    code: "WARRIOR_FEMALE",
    house: "WARRIOR",
    gender: "FEMALE",
    images: {
      main: {
        url: "/assets/characters/warrior-female/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/characters/warrior-female/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/characters/warrior-female/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
  ROBOT: {
    code: "ROBOT",
    house: "ROBOT",
    gender: "UNKNOWN",
    images: {
      main: {
        url: "/assets/characters/robot/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/characters/robot/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/characters/robot/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
  WITCH_MALE: {
    code: "WITCH_MALE",
    house: "WITCH",
    gender: "MALE",
    images: {
      main: {
        url: "/assets/characters/witch-male/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/characters/witch-male/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/characters/witch-male/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
  WITCH_FEMALE: {
    code: "WITCH_FEMALE",
    house: "WITCH",
    gender: "FEMALE",
    images: {
      main: {
        url: "/assets/characters/witch-female/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/characters/witch-female/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/characters/witch-female/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
  VAMPIRE: {
    code: "VAMPIRE",
    house: "VAMPIRE",
    gender: "UNKNOWN",
    images: {
      main: {
        url: "/assets/characters/vampire/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/characters/vampire/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/characters/vampire/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
  NOBLEMAN_MALE: {
    code: "NOBLEMAN_MALE",
    house: "NOBLEMAN",
    gender: "MALE",
    images: {
      main: {
        url: "/assets/nobleman-male/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/nobleman-male/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/nobleman-male/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
  NOBLEMAN_FEMALE: {
    code: "NOBLEMAN_FEMALE",
    house: "NOBLEMAN",
    gender: "FEMALE",
    images: {
      main: {
        url: "/assets/nobleman-female/main.png",
        width: 250,
        height: 312,
        totalFrame: 4,
        lineFrame: 4,
        lineOffset: 0,
        frameOffset: 0,
      },
      game: {
        normal: {
          url: "/assets/nobleman-female/game/normal.png",
          width: 114,
          height: 95,
          totalFrame: 14,
          lineFrame: 11,
          lineOffset: 0,
          frameOffset: 0,
        },
        attack: {
          url: "/assets/nobleman-female/game/attack.png",
          width: 114,
          height: 95,
          totalFrame: 15,
          lineFrame: 11,
          lineOffset: 1,
          frameOffset: 6,
        },
      },
    },
  },
};
