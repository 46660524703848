import { first } from "lodash";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { HOUSE_CONFIGURATIONS } from "../../constants";
import { GameHouse } from "../../enums";
import { Progress } from "../../interfaces";
import { useAppSelector } from "../../modules/redux/hook";
import CombineAnimationComponent, {
  CombineAnimationComponentRef,
  DrawInfo,
} from "../animation/combine-animation.component";
import AttackComponent from "../attack/attack.component";

interface MatchComponentProps {
  //   state?: "NORMAL" | "LEFT_ATTACK" | "RIGHT_ATTACK";
  dimensions: {
    width: number;
    height: number;
  };
}

type onFightProps = {
  fightState: "LEFT_ATTACK" | "RIGHT_ATTACK";
  process: Progress;
};

export type MatchComponentRef = {
  onfight: (props: onFightProps) => Promise<void>;
};

const MatchComponent = forwardRef(
  ({ dimensions }: MatchComponentProps, ref: any) => {
    const animationComponentRef = useRef<CombineAnimationComponentRef>();
    const [stage, setStage] = useState<
      "LEFT_ATTACK" | "RIGHT_ATTACK" | "NORMAL"
    >("NORMAL");
    const { matchResult } = useAppSelector(({ app }) => app);
    const attackRef = useRef<{ fight: (props: any) => void }>();
    const [fightStage, setFightStage] = useState<onFightProps>();
    const [characterInfo, setCharacterInfo] = useState<{
      left: DrawInfo;
      right: DrawInfo;
    }>();

    useEffect(() => {
      const leftConfig =
        HOUSE_CONFIGURATIONS[
          matchResult?.initData?.leftFullGameProfile?.houseData.name!
        ] || GameHouse.NOBLEMAN_FEMALE;
      const rightConfig =
        HOUSE_CONFIGURATIONS[
          matchResult?.initData?.rightFullGameProfile?.houseData.name!
        ] || GameHouse.NOBLEMAN_FEMALE;

      const leftStage = stage === "LEFT_ATTACK" ? "attack" : "normal";
      const rightStage = stage === "RIGHT_ATTACK" ? "attack" : "normal";

      setCharacterInfo({
        left: {
          src: leftConfig.images.game[leftStage].url!,
          width: leftConfig.images.game[leftStage].width!,
          height: leftConfig.images.game[leftStage].height!,
          totalFrame: leftConfig.images.game[leftStage].totalFrame!,
          lineFrame: leftConfig.images.game[leftStage].lineFrame!,
          lineOffset: leftConfig.images.game[leftStage].lineOffset!,
          frameOffset: leftConfig.images.game[leftStage].frameOffset!,
          sizeRatio: 1,
          location: {
            x: 0,
            y: Math.floor(dimensions.height - 20 - 95 * 1),
          },
          flip: false,
        },
        right: {
          src: rightConfig.images.game[rightStage].url!,
          width: rightConfig.images.game[rightStage].width!,
          height: rightConfig.images.game[rightStage].height!,
          totalFrame: rightConfig.images.game[rightStage].totalFrame!,
          lineFrame: rightConfig.images.game[rightStage].lineFrame!,
          lineOffset: rightConfig.images.game[rightStage].lineOffset!,
          frameOffset: rightConfig.images.game[rightStage].frameOffset!,
          sizeRatio: 1,
          location: {
            x: Math.floor(dimensions.width - 114 * 1),
            y: Math.floor(dimensions.height - 20 - 95 * 1),
          },
          flip: true,
        },
      });
    }, [matchResult, stage]);

    useEffect(() => {
      if (!animationComponentRef.current) return;

      animationComponentRef.current?.forceRender(
        [characterInfo?.left!, characterInfo?.right!],
        ["LEFT_ATTACK", "RIGHT_ATTACK"].includes(stage) ? "ATTACK" : "NORMAL",
      );
    }, [characterInfo, animationComponentRef.current]);

    useImperativeHandle(ref, () => {
      return {
        onfight: (props: onFightProps) => {
          setFightStage(props);
          setStage(props.fightState);
        },
      };
    });

    const onRenderFight = () => {
      if (!fightStage) return;
      attackRef.current!.fight({
        direction:
          fightStage.fightState === "LEFT_ATTACK"
            ? "LeftToRight"
            : "RightToLeft",
        speed: 10,
        shotType:
          first(fightStage.process.steps)?.type === "NORMAL_DAMGE"
            ? "Normal"
            : "Critical",
        isMiss: false,
        onFinished: () => {
          setStage("NORMAL");
        },
        options: {
          leftHeroX: 15,
          rightHeroX: (dimensions.width || 0) - 15,
          topBound: 50, // TODO Hardcode
          groundBound: 50,
        },
      });
    };

    if (!characterInfo) return null;
    
    return (
      <>
        <AttackComponent
          ref={attackRef}
          baseTime={5}
          width={dimensions.width}
          height={dimensions.height}
        />
        <CombineAnimationComponent
          onLastFrame={async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));
            onRenderFight();
          }}
          width={dimensions.width}
          height={dimensions.height}
          ref={animationComponentRef}
          frameTime={250}
          drawInformations={[characterInfo?.left!, characterInfo?.right!]}
        />
      </>
    );
  },
);

export default MatchComponent;
