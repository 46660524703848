import Footer from "../footer";
import styles from "./layout.module.css";

const Layout = ({
  children,
  hidenFooter = false,
}: {
  children: React.ReactNode;
  hidenFooter?: boolean;
}) => {
  return (
    <>
      <div
        className={styles.layoutContainer}
        style={hidenFooter ? { height: "100%", minHeight: "100%" } : {}}
      >
        {children}
      </div>
      {!hidenFooter && <Footer />}
    </>
  );
};

export default Layout;
