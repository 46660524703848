import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HouseData } from "../../interfaces/configuration-data.interface";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hook";
import { requestChangeHouse } from "../../modules/redux/slices/app.slice";
import styles from "./house.module.css";
import { GameHouse } from "../../enums";
const HousePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isChanged, setIsChanged] = useState(false);
  const { appInformation, gameProfile } = useAppSelector(({ app }) => app);
  const [house, setHouse] = useState<Record<string, HouseData>>();
  const onChangeHouse = (gameHouse: GameHouse, house: HouseData) => {
    if (gameProfile?.houseData.name === gameHouse) return;
    dispatch(
      requestChangeHouse({
        house: gameHouse,
      }),
    );
    setIsChanged(true);
  };

  useEffect(() => {
    const rs = groupByHouse({
      WARRIOR_MALE: {
        code: "WARRIOR_MALE",
        house: "WARRIOR",
        gender: "MALE",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits306/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits306/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits306/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
      WARRIOR_FEMALE: {
        code: "WARRIOR_FEMALE",
        house: "WARRIOR",
        gender: "FEMALE",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits307/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits307/1/game.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits307/1/game.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
      ROBOT: {
        code: "ROBOT",
        house: "ROBOT",
        gender: "",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits310/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits310/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits310/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
      WITCH_MALE: {
        code: "WITCH_MALE",
        house: "WITCH",
        gender: "MALE",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits239/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits239/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits239/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
      WITCH_FEMALE: {
        code: "WITCH_FEMALE",
        house: "WITCH",
        gender: "FEMALE",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits147/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits147/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits147/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
      VAMPIRE: {
        code: "VAMPIRE",
        house: "VAMPIRE",
        gender: "",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits320/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits320/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits320/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
      NOBLEMAN_MALE: {
        code: "NOBLEMAN_MALE",
        house: "NOBLEMAN",
        gender: "MALE",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits170/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits170/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits170/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
      NOBLEMAN_FEMALE: {
        code: "NOBLEMAN_FEMALE",
        house: "NOBLEMAN",
        gender: "FEMALE",
        images: {
          main: {
            url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits160/1/show.png",
            width: 250,
            height: 312,
            totalFrame: 4,
            lineFrame: 4,
            lineOffset: 0,
            frameOffset: 0,
          },
          game: {
            normal: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits160/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 14,
              lineFrame: 11,
              lineOffset: 0,
              frameOffset: 0,
            },
            attack: {
              url: "https://github.com/telegram-game/game-resource/blob/main/image/virtual/m/suits/suits160/1/game1.png",
              width: 114,
              height: 95,
              totalFrame: 15,
              lineFrame: 11,
              lineOffset: 1,
              frameOffset: 6,
            },
          },
        },
      },
    });
    console.log(rs);
    setHouse(rs);
  }, [appInformation]);

  const groupByHouse = (data: any) => {
    const grouped: Record<string, any> = {};
    // Iterate over the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const item = data[key];
        const house = item.house;

        // If the house doesn't exist in the grouped object, create it
        if (!grouped[house]) {
          grouped[house] = {};
        }

        // Add the current item to the appropriate house
        grouped[house][key] = item;
      }
    }

    return grouped;
  };

  useEffect(() => {
    if (!isChanged) return;
    navigate("/kombat");
  }, [gameProfile?.houseData, isChanged, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {Object.entries(house ?? {}).map(([key, value]) => (
          <div
            key={key}
            className={`${styles.item} ${
              gameProfile?.houseData.name === value.name ? styles.active : ""
            }`}
          >
            <div>{key}</div>
            {Object.entries(value).map(([key, value]) => {
              return (
                <div
                  key={key}
                  onClick={() => onChangeHouse(key as GameHouse, value)}
                >
                  <div className={styles.title}>{value.code}</div>
                  <div className={styles.description}>{value.description}</div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HousePage;
