import React, { useEffect, useMemo, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { images } from "../../../constants";
import { ItemSupports } from "../../../enums";
import { Hero } from "../../../interfaces";
import { AppState } from "../../../modules/redux/slices/app.slice";
import Character from "../../character/character";
import InventoryItem from "../inventory-item";
import styles from "./inventory-hero.module.css";

type InventorHeroProps = {
  items?: AppState["inventories"];
  hero: Hero | undefined;
  onItemClick: (item: any) => void;
};

const InventoryHeroComponent = ({
  items,
  hero,
  onItemClick,
}: InventorHeroProps) => {
  const itemRef = React.useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (itemRef.current) {
        const { offsetWidth, offsetHeight } = itemRef.current;
        setDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const getImageByItemCode = (itemCode: string, type: string) => {
    return (images.items as any)[itemCode] || (images.itemTypes as any)[type];
  };
  const character = useMemo(() => {
    return (
      <div
        className={styles.character}
        style={{
          height: dimensions.height,
        }}
      >
        <Character sizeRatio={0.7} />
      </div>
    );
  }, [dimensions]);

  const itemMemo = React.useMemo(() => {
    const el: Array<React.ReactNode> = [];
    if (!items?.length) return <div></div>;

    for (let i = 0; i < ItemSupports.length; i += 2) {
      let default1 = ItemSupports[i];
      let default2 = ItemSupports[i + 1];
      const heroItem1 = hero?.items?.find(
        (x) => x.itemType === default1.itemType
      );
      const heroItem2 = hero?.items?.find(
        (x) => x.itemType === default2.itemType
      );
      el.push(
        <Fragment key={i}>
          <InventoryItem
            onClick={() => {
              heroItem1 && onItemClick && onItemClick(heroItem1);
            }}
            item={{
              inventoryId: heroItem1?.id,
              image: getImageByItemCode(heroItem1?.itemCode, default1.itemType),
              itemType: heroItem1?.itemType,
              isEmpty: !heroItem1?.itemCode,
            }}
          />
          <div></div>
          <InventoryItem
            onClick={(item) => {
              heroItem2 && onItemClick && onItemClick(heroItem2);
            }}
            item={{
              inventoryId: heroItem2?.id,
              image: getImageByItemCode(heroItem2?.itemCode, default2.itemType),
              itemType: heroItem2?.itemType,
              isEmpty: !heroItem2?.itemCode,
            }}
          />
        </Fragment>
      );
    }
    return el;
  }, [items]);

  return (
    <>
      <div className={styles.container}>
        <div ref={itemRef} className={styles.item}>
          {itemMemo}
        </div>
        {character}
      </div>
    </>
  );
};

export default InventoryHeroComponent;
