import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { images } from "../../constants";

type AttackProps = {
  width: number;
  height: number;
  baseTime: number;
};

type Direction = "LeftToRight" | "RightToLeft";
const AttackComponent = forwardRef(
  ({ width, height, baseTime }: AttackProps, ref) => {
    const frameTime = 30;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const renderingContext = useRef<CanvasRenderingContext2D | null>(null);

    useImperativeHandle(ref, () => ({
      fight,
    }));

    useEffect(() => {
      if (!canvasRef.current) return;
      renderingContext.current = canvasRef.current!.getContext("2d");
    }, [canvasRef]);

    const drawShotByType = (
      type: string,
      x: number,
      y: number,
      radius: number = 20
    ) => {
      const ctx = renderingContext.current!;
      const img = document.createElement("img");
      img.style.width = `${radius}px`;
      img.style.height = `${radius}px`;
      if (type === "Normal") {
        img.src = "/assets/damage/frozen.png";
        ctx.drawImage(img, x, y, radius, radius);
      } else if (type === "Critical") {
        img.src = "/assets/damage/critical.png";
        ctx.drawImage(img, x, y, radius, radius);
      } else {
        ctx.beginPath();
        ctx.fillStyle = "red";
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.fill();
      }
    };

    const clearAfterCompleted = () => {
      const ctx = renderingContext.current!;
      ctx.clearRect(0, 0, width, height);
    };

    const getShotlocationAlgorithm = ({
      isMiss,
      direction,
      speed,
      startLocation,
      area,
    }: {
      isMiss: boolean;
      direction: Direction;
      speed: number;
      startLocation: any;
      area: any;
    }) => {
      const { paddingLeft, paddingTop, paddingRight, paddingBottom } = area;
      const { x, y } = startLocation;
      const time = baseTime / speed; // s
      const haftTime = time / 2;

      const distanceX = (() => {
        if (direction === "LeftToRight") {
          return width - x - paddingRight;
        } else {
          return x - paddingLeft;
        }
      })();
      let vX0 = distanceX / time;

      // 50% chance to open the shield
      // If not open the shield, the shot will be missed via the random direction
      let isOpenShield = false;
      if (isMiss) {
        isOpenShield = Math.random() > 0.5;
        if (!isOpenShield) {
          vX0 = vX0 - Math.random() * 0.4 * vX0 - 0.1 * vX0;
        }
      }

      const distanceY = height - paddingBottom - paddingTop;
      const g = (-1 * (2 * distanceY)) / Math.pow(haftTime, 2); // Why -1 because the y axis is from top to bottom
      let vY0 = (-1 * (distanceY - 0.5 * g * Math.pow(haftTime, 2))) / haftTime; // Why -1 because the y axis is from top to bottom

      let currentTimeX = performance.now();
      let currentTimeY = performance.now();
      let currentX = x;
      let currentY = y;
      const xFunction = () => {
        const now = performance.now();
        const detaTime = (now - currentTimeX) / 1000;
        const distance = detaTime * vX0;
        if (direction === "LeftToRight") {
          currentX += distance; // LeftToRight
        } else if (direction === "RightToLeft") {
          currentX -= distance; // RightToLeft
        }

        currentTimeX = now;
        return currentX;
      };

      const yFunction = () => {
        const now = performance.now();
        const detaTime = (now - currentTimeY) / 1000;
        vY0 = vY0 - g * detaTime;
        const deltaDistance = detaTime * vY0;
        currentY += deltaDistance;

        currentTimeY = now;
        return currentY;
      };

      return {
        isOpenShield,
        calculateX: xFunction,
        calculateY: yFunction,
      };
    };

    const drawLine = (y: any, width: any) => {
      const ctx = renderingContext.current!;

      ctx.strokeStyle = "white";
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(width, y);
      ctx.stroke();
    };

    const drawYLine = (x: any, height: any) => {
      const ctx = renderingContext.current!;

      ctx.strokeStyle = "white";
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
      ctx.stroke();
    };

    const fight = ({
      direction,
      speed,
      shotType,
      isMiss,
      onFinished,
      options,
    }: {
      direction: Direction;
      speed: number;
      shotType: "Normal" | "Critical" | "Frozen";
      isMiss: boolean;
      onFinished: (isOpenShield: boolean) => void;
      options: {
        leftHeroX: number;
        rightHeroX: number;
        topBound: number;
        groundBound: number;
      };
    }) => {
      const { leftHeroX, rightHeroX, topBound, groundBound } = options;
      // Run the shot with time frame is 30ms (30 frames per second) until the shot is complete
      // Run the performance interval with 30ms
      let currentX = (() => {
        if (direction === "LeftToRight") {
          return leftHeroX;
        } else {
          return rightHeroX;
        }
      })();
      let currentY = height - groundBound;
      const { calculateX, calculateY, isOpenShield } = getShotlocationAlgorithm(
        {
          isMiss,
          direction,
          speed,
          startLocation: { x: currentX, y: currentY },
          area: {
            paddingLeft: leftHeroX,
            paddingTop: topBound,
            paddingRight: width - rightHeroX,
            paddingBottom: groundBound,
          },
        }
      );
      let shouldClearInterval = false;
      const interval = setInterval(() => {
        // The purpose for this interval is to draw the last shot on the canvas
        if (shouldClearInterval) {
          clearInterval(interval);
          clearAfterCompleted();
          onFinished && onFinished(isOpenShield);
          return;
        }

        clearAfterCompleted();
        drawShotByType(shotType, currentX, currentY);

        /* Debug */
        // // Draw top and bottom line for debug
        // drawLine(topBound, width);
        // drawLine(height - groundBound, width);
        // drawYLine(rightHeroX, height);
        // drawYLine(leftHeroX, height);

        currentX = calculateX();
        currentY = calculateY();
        if (
          !shouldClearInterval &&
          (currentX >= rightHeroX ||
            currentX <= leftHeroX ||
            currentY >= height - groundBound)
        ) {
          shouldClearInterval = true;
        }
      }, frameTime);
    };

    return <canvas ref={canvasRef} width={width} height={height} />;
  }
);

export default AttackComponent;
