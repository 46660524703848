// Table.tsx
import styles from "./table.module.css";

interface TableProps {
  headers: Array<string>;
  items: Array<Record<string, React.ReactNode>>;
}
const Table = ({ headers, items }: TableProps) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.tr}>
          {headers.map((header, index) => (
            <th key={index} className={styles.th}>
              <div className={styles.thContent}>{header}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!items?.length ? (
          <tr className={styles.tr}>
            <td colSpan={headers.length}>No Data</td>
          </tr>
        ) : (
          items.map((row, index) => (
            <tr key={index} className={styles.tr}>
              {Object.entries(row).map(([key, value]) => (
                <td key={key} className={styles.td}>
                  {value}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default Table;
