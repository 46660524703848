import { RouteObject } from "react-router-dom";
import Header from "./components/header/header.component";
import Layout from "./components/layout";
import { images } from "./constants";
import AirdropPage from "./pages/airdrop";
import EarnPage from "./pages/earn";
import NotFoundPage from "./pages/errors/404";
import FightPage from "./pages/fight";
import FriendPage from "./pages/friend";
import HomePage from "./pages/home/index";
import HousePage from "./pages/house";
import InventoryPage from "./pages/inventory";
import KombatPage from "./pages/kombat";
import ProfilePage from "./pages/profile";
import SeasonPage from "./pages/season";
import ShopPage from "./pages/shop";
import StatsPage from "./pages/stats";
import TierPage from "./pages/tier";
import UnsupportPage from "./pages/unsupport";
import UpgradePage from "./pages/upgrade";

export const routes: Array<
  RouteObject & { name: string; isNav: boolean; icon?: string }
> = [
  {
    path: "/",
    name: "home",
    isNav: false,
    element: (
      <Layout>
        <HomePage />
      </Layout>
    ),
  },
  {
    path: "/earn",
    name: "Missions",
    element: (
      <Layout>
        <EarnPage />
      </Layout>
    ),
    icon: images.earn,
    isNav: true,
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    element: (
      <Layout>
        <UpgradePage />
      </Layout>
    ),
    icon: images.upgrade,
    isNav: true,
  },
  {
    path: "/kombat",
    name: "Rank",
    element: (
      <Layout>
        <KombatPage />
      </Layout>
    ),
    icon: images.kombat,
    isNav: true,
  },
  {
    path: "/kombat/inventory",
    name: "Inventory",
    element: (
      <Layout>
        <Header isShowChampion={false} />
        <InventoryPage />
      </Layout>
    ),
    isNav: false,
  },
  {
    path: "/friend",
    name: "Friend",
    element: (
      <Layout>
        <FriendPage />
      </Layout>
    ),
    icon: images.friend,
    isNav: true,
  },
  // {
  //   path: "/airdrop",
  //   name: "airdrop",
  //   element: (
  //     <Layout>
  //       <AirdropPage />
  //     </Layout>
  //   ),
  //   icon: images.airdrop,
  //   isNav: true,
  // },
  {
    path: "/fight",
    name: "Fight",
    element: (
      <Layout hidenFooter={true}>
        <FightPage />
      </Layout>
    ),
    isNav: false,
  },
  {
    path: "*",
    name: "404",
    isNav: false,
    element: <NotFoundPage />,
  },
  {
    path: "unsupport",
    name: "unsupport",
    isNav: false,
    element: <UnsupportPage />,
  },
  {
    path: "shop",
    name: "Shop",
    isNav: false,
    element: (
      <Layout>
        <ShopPage />
      </Layout>
    ),
  },
  {
    path: "house",
    name: "House",
    isNav: false,
    element: (
      <Layout>
        <HousePage />
      </Layout>
    ),
  },
  {
    path: "tier",
    name: "Tier",
    isNav: false,
    element: (
      <Layout>
        <TierPage />
      </Layout>
    ),
  },
  {
    path: "stats",
    name: "Stats",
    isNav: false,
    element: (
      <Layout>
        <Header />
        <StatsPage />
      </Layout>
    ),
  },
  {
    path: "profile",
    name: "Profile",
    isNav: false,
    element: (
      <Layout>
        <ProfilePage />
      </Layout>
    ),
  },
  {
    path: "season",
    name: "Season",
    isNav: false,
    element: (
      <Layout>
        <SeasonPage />
      </Layout>
    ),
  },
];
