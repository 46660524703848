import { get } from "lodash";
import { useEffect, useMemo } from "react";
import Header from "../../components/header/header.component";
import InventoryHeroComponent from "../../components/inventory/inventory-hero/inventory-hero.component";
import { images } from "../../constants";
import { useAppSelector } from "../../modules/redux/hook";
import styles from "./stats.module.css";

const StatsPage = () => {
  const app = useAppSelector(({ app }) => app);
  useEffect(() => {
    console.log({
      app,
    });
  }, [app]);

  const items = useMemo(() => {
    if (!app.inventories?.length) return;
    return app.inventories.map((item, index) => {
      return (
        <div key={index} className={styles.skillItem}>
          <div className={styles.skillInfo}>
            {item.userGameInventoryAttributes
              .filter(({ canRoll }) => !canRoll)
              .map((attr, index) => {
                return (
                  <p key={index} className={styles.skillName}>
                    {attr.attribute}&nbsp;<span>+{attr.value.point}</span>
                  </p>
                );
              })}

            <div className={styles.skillBuffer}>
              {item.userGameInventoryAttributes
                .filter(({ canRoll }) => canRoll)
                .map((attr, index) => {
                  return (
                    <div key={index} className={styles.buffer}>
                      <p> {attr.attribute}</p>&nbsp;
                      <p>
                        {attr.value.point > 0 ? (
                          <>{attr.value.point}&nbsp;</>
                        ) : null}
                        {attr.value.percent > 0 ? (
                          <>{attr.value.percent || 0}%&nbsp;</>
                        ) : null}
                        {attr.value.percentPerTime > 0 ? (
                          <>{attr.value.percentPerTime || 0}&nbsp;</>
                        ) : null}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={styles.skillImage}>
            <img src={images.attack} alt="" />
          </div>
        </div>
      );
    });
  }, [app.inventories]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.character}>
          <InventoryHeroComponent
            items={app.inventories as any}
            hero={app.hero}
            onItemClick={() => {}}
          />
        </div>
        <div className={styles.content}>
          <div className={`${styles.section} ${styles.profile}`}>
            <div className={styles.attack}>
              <img src={images.attack} alt="Attack" />
              &nbsp;{1240}(+10)
            </div>
            <div className={styles.hp}>
              <img src={images.hp} alt="HP" />
              &nbsp;{9091}(+30)
            </div>
            <div className={styles.luck}>
              <img src={images.luck} alt="Luck" />
              &nbsp;{1240}(+10)
            </div>
          </div>
          <div className={`${styles.section} ${styles.rate}`}>
            <div className={styles.match}>
              <p>
                Total match: <span>1131</span>
                <img src={images.info} alt="Info" />
              </p>
            </div>
            <div className={styles.win}>
              <p>
                Win rate: <span>97.5%</span>
              </p>
            </div>
          </div>
          <div className={`${styles.section} ${styles.characterInfo}`}>
            {[
              {
                code: "hp",
                name: "HP",
                value: 1240,
                buffer: "(+10)",
              },
              {
                code: "evasion",
                name: "Evasion",
                value: 1240,
                buffer: "(+10)",
              },
              {
                code: "attack",
                name: "Attack",
                value: 1240,
                buffer: "(+10)",
              },
              {
                code: "evasion",
                name: "Life steal",
                value: 1240,
                buffer: "(+10)",
              },
              {
                code: "evasion",
                name: "Crit rate",
                value: 1240,
                buffer: "(+10)",
              },
              {
                code: "evasion",
                name: "Reflect",
                value: 1240,
                buffer: "(+10)",
              },
              {
                code: "evasion",
                name: "Crit DMG",
                value: 1240,
                buffer: "(+10)",
              },
              {
                code: "evasion",
                name: "Skill",
                value: 1240,
                buffer: "(+10)",
              },
            ].map((item, index) => (
              <div key={index} className={styles.gridItem}>
                <p
                  className={`${
                    styles[index % 2 ? "statNameRight" : "statNameLeft"]
                  }`}
                >
                  <img src={get(images, [item.code])} alt={item.code} />
                  &nbsp;
                  {item.name}
                </p>
                <p
                  className={`${styles.statsValue} ${
                    styles[index % 2 ? "statValueRight" : "statValueLeft"]
                  }`}
                >
                  <span>{item.value}</span>
                  <span>{item.buffer}</span>
                </p>
              </div>
            ))}
          </div>
          <div className={`${styles.section} ${styles.skill}`}>{items}</div>
        </div>
      </div>
    </>
  );
};

export default StatsPage;
