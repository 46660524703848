import { useEffect, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import { HOUSE_CONFIGURATIONS } from "../../constants";
import { GameHouse } from "../../enums";
import { HouseCharacter } from "../../interfaces";
import { useAppSelector } from "../../modules/redux/hook";
import CombineAnimationComponent, {
  CombineAnimationComponentRef,
} from "../animation/combine-animation.component";

interface CharacterProps {
  sizeRatio?: number;
  location?: "HOME" | "KOMBAT" | "INVENTORY";
}

const Character = ({ sizeRatio = 1, location = "HOME" }: CharacterProps) => {
  const { gameProfile } = useAppSelector(({ app }) => app, shallowEqual);
  const [characterInfo, setCharacterInfo] = useState<HouseCharacter>();
  const animationComponentRef = useRef<CombineAnimationComponentRef>();

  useEffect(() => {
    setCharacterInfo(
      HOUSE_CONFIGURATIONS[gameProfile?.houseData.name!] ||
        GameHouse.NOBLEMAN_FEMALE
    );
  }, [gameProfile?.houseData]);

  useEffect(() => {
    if (!characterInfo) return;

    const newDrawInformations = {
      src: characterInfo?.images?.main.url!,
      width: characterInfo?.images?.main.width!,
      height: characterInfo?.images?.main.height!,
      totalFrame: characterInfo?.images?.main.totalFrame!,
      lineFrame: characterInfo?.images?.main.lineFrame!,
      lineOffset: characterInfo?.images?.main.lineOffset!,
      frameOffset: characterInfo?.images?.main.frameOffset!,
      sizeRatio,
      location: {
        x: ((1 - sizeRatio) * 250) / 2,
        y: ((1 - sizeRatio) * 312) / 2,
      },
    };

    // update item
    animationComponentRef.current?.forceRender([newDrawInformations]);
  }, [characterInfo, location, gameProfile?.houseData, sizeRatio]);

  if (!characterInfo) return <></>;
  return (
    <>
      <CombineAnimationComponent
        width={250}
        height={312}
        frameTime={250}
        ref={animationComponentRef}
        drawInformations={[
          {
            src: characterInfo?.images?.main.url!,
            width: characterInfo?.images?.main.width!,
            height: characterInfo?.images?.main.height!,
            totalFrame: characterInfo?.images?.main.totalFrame!,
            lineFrame: characterInfo?.images?.main.lineFrame!,
            lineOffset: characterInfo?.images?.main.lineOffset!,
            frameOffset: characterInfo?.images?.main.frameOffset!,
            sizeRatio,
            location: {
              x: ((1 - sizeRatio) * 250) / 2,
              y: ((1 - sizeRatio) * 312) / 2,
            },
          },
        ]}
      />
    </>
  );
};

export default Character;
