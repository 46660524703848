import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import Slider from "../../components/slider/slider";
import Table from "../../components/table/table";
import { images } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hook";
import { getSeasonTier } from "../../modules/redux/slices/app.slice";
import styles from "./season.module.css";

const SeasonPage = () => {
  const dispatch = useAppDispatch();
  const { appInformation, seasonTier, gameProfile } = useAppSelector(
    ({ app }) => app
  );
  const [tier, setTier] = useState<string>();

  useEffect(() => {
    if (!tier) return;
    dispatch(getSeasonTier(tier!));
  }, [tier]);

  useEffect(() => {
    Object.entries(appInformation?.game.rank.range || {}).map(
      ([key, value]) => {
        return {
          value: value.code,
          imaage: get(images, ["seasonTiers", value.code]),
        };
      }
    );
  }, [appInformation]);

  const seasons = useMemo(() => {
    if (!seasonTier || !tier) return;
    return (
      <div className={styles.content}>
        <Table
          headers={["Rank", "Warrior", "RP"]}
          items={
            seasonTier[tier!]?.topUsers.map((client, index) => ({
              rank: get(images, ["rank", index + 1]) ? (
                <img
                  style={{
                    width: "25px",
                    height: "32px",
                  }}
                  src={get(images, ["rank", index + 1])}
                  alt="Rank"
                />
              ) : (
                index + 1
              ),
              warrior: client.firstName + " " + client.lastName,
              rp: client.rankPoint,
            })) || []
          }
        />
      </div>
    );
  }, [seasonTier, tier]);

  return (
    <div className={styles.container}>
      <div className={styles.notice}>
        <p>
          <img src={images.info} alt="Info" />
          &nbsp;
          <span>Season {gameProfile?.currentSeason?.name}</span>&nbsp;-&nbsp;
          Ends in&nbsp;<span>3 days</span>
        </p>
      </div>
      <div className={styles.header}>
        <Slider
          items={Object.entries(appInformation?.game.rank.range || {}).map(
            ([key, value]) => {
              return {
                value: value.code,
                image: get(images, ["seasonTiers", value.code]),
              };
            }
          )}
          imageStyles={{
            width: "250px",
            height: "250px",
          }}
          itemsPerSlide={1}
          onChange={(value) => setTier(String(value))}
        />
      </div>
      {seasons}
    </div>
  );
};

export default SeasonPage;
