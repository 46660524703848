// Slider.tsx
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./slider.module.css";

interface SliderProps {
  items: {
    image: string;
    value: string | number;
  }[];
  onChange?: (value: string | number) => void;
  imageStyles?: React.CSSProperties;
  itemsPerSlide?: number; // Number of items to display per slide, default is 2
}

const Slider: React.FC<SliderProps> = ({
  imageStyles,
  items,
  itemsPerSlide = 1,
  onChange,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const totalSlides = Math.ceil(items.length / itemsPerSlide);
  useEffect(() => {
    if (!items?.length) return;

    // Create a debounced version of onChange
    const debouncedOnChange = debounce((value) => {
      if (onChange) {
        onChange(value);
      }
    }, 300);
    debouncedOnChange(items[currentSlide].value);

    return () => {
      debouncedOnChange.cancel();
    };
  }, [currentSlide, items, onChange]);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const slideTranslateX = -currentSlide * 100;

  return (
    <div className={styles.sliderWrapper}>
      <div
        className={styles.slider}
        style={{ transform: `translateX(${slideTranslateX}%)` }}
      >
        {items.map((imageUrl, index) => (
          <div
            key={index}
            className={styles.item}
            style={{ flex: `1 0 ${100 / itemsPerSlide}%` }}
          >
            <img
              src={imageUrl.image}
              alt={`Slide ${index + 1}`}
              style={imageStyles}
              className={styles.image}
            />
          </div>
        ))}
      </div>
      <button className={styles.prev} onClick={prevSlide}>
        &laquo;
      </button>
      <button className={styles.next} onClick={nextSlide}>
        &raquo;
      </button>
    </div>
  );
};

export default Slider;
