import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { getImageByItemCode, images } from "../../../constants";
import { useAppSelector } from "../../../modules/redux/hook";
import mainStyles from "../popup.module.css";
import styles from "./inventory-popup.module.css";

interface InventoryPopupProps {
  onClose: () => void;
  onConfirm: (skill: string) => void;
  onReRoll: (data: any) => void;
  inventoryItem: {
    name: string;
    id: string;
    itemCode: string;
    image: string;
    itemType: string;
    rerollCount: number;
    reRollCost: number;
    star: number;
    userGameInventoryAttributes: Array<{
      id: string;
      attribute: string;
      value: {
        percent: number;
        percentPerTime: number;
        point: number;
      };
      star: number;
      canRoll: boolean;
    }>;
  };
}
const InventoryPopupComponent = ({
  onClose,
  onConfirm,
  inventoryItem,
  onReRoll,
}: InventoryPopupProps) => {
  const { me, gameProfile, appInformation } = useAppSelector(({ app }) => app);
  const [selectedSkill, setSelectedSkill] = useState<string>();
  const [rollAttributes, setRollAttributes] = useState<string[]>([]);

  const renderValue = (
    item: InventoryPopupProps["inventoryItem"]["userGameInventoryAttributes"][0]
  ) => {
    return (
      <>
        &nbsp;
        {item.value.percent > 0 ? `+${item.value.percent}% ` : ""}
        {item.value.point > 0 ? `+${item.value.point} ` : ""}
        &nbsp;
        {item.attribute}
      </>
    );
  };
  const comonValue = () => {
    const val = inventoryItem.userGameInventoryAttributes.find(
      ({ canRoll }) => !canRoll
    );
    return <p className={styles[`level${val!.star}`]}>{renderValue(val!)}</p>;
  };
  useEffect(() => {
    setSelectedSkill(gameProfile?.hero?.skill);
  }, [gameProfile?.hero]);

  const rollButton = useMemo(() => {
    return (
      <div className={styles.actionButton}>
        <button
          disabled={rollAttributes?.length === 0}
          className={`${styles.rollButton} ${
            rollAttributes?.length === 0 && styles.disabled
          }`}
          onClick={() =>
            onReRoll({
              inventoryId: inventoryItem.id,
              attributes: rollAttributes,
            })
          }
        >
          <img
            className={styles.tokenIcon}
            src={images.coin.INGAME}
            alt="Token Icon"
          />
          {(inventoryItem.reRollCost || 1) * inventoryItem.rerollCount}
          &nbsp;Re-roll stats
        </button>
      </div>
    );
  }, [rollAttributes]);

  return (
    <div className={`${styles.container} ${mainStyles.bgPopup}`}>
      <div className={styles.controlButtonArea}>
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
      </div>
      <div className={styles.popupContent}>
        <div className={styles.header}>
          <div className={styles.token}>
            <img src={images.coin.INGAME} alt="Token Icon" />{" "}
            <p>{(me?.balances.INGAME || 0).toFixed(5)}</p>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.info}>
            {get(appInformation?.items?.items, [inventoryItem.itemCode]) ? (
              <>
                <h3>
                  {get(appInformation?.items?.items, [inventoryItem.itemCode])
                    ?.name || "NA"}
                </h3>
                <p>#{inventoryItem.itemCode}</p>
              </>
            ) : (
              <>
                <h3>N/A</h3>
                <p>N/A</p>
              </>
            )}
          </div>
          <div className={styles.image}>
            <img
              className={styles.itemImage}
              src={getImageByItemCode(
                inventoryItem.itemCode,
                inventoryItem.itemType
              )}
              alt=""
            />
            <div className={styles.itemLevel}>
              {Array(inventoryItem.star)
                .fill(0)
                .map((_, index) => (
                  <img key={index} src={images.coin.star} alt="" />
                ))}
            </div>
          </div>
          <div className={styles.itemStats}>{comonValue()}</div>
          <div className={styles.detail}>
            {inventoryItem.userGameInventoryAttributes
              .filter(({ canRoll }) => canRoll)
              .map((item, index) => (
                <div className={styles.itemStats} key={index}>
                  <p className={styles[`level${item.star}`]}>
                    <input
                      type="checkbox"
                      name={item.id}
                      id={item.id}
                      checked={[...rollAttributes].includes(item.id)}
                      onChange={(e) => {
                        if (e.target.checked)
                          setRollAttributes((prev) => {
                            return [...prev, item.id];
                          });
                        else {
                          setRollAttributes((prev) => {
                            return prev.filter((skill) => skill !== item.id);
                          });
                        }
                      }}
                    />
                    {renderValue(item)}
                  </p>
                </div>
              ))}
            {rollButton}
          </div>
          <div className={styles.hint}>
            <p>
              Re-roll lets players replace an item's bonus stats. The cost
              increases with each re-roll but resets to 1 TOK daily at 00:00
              UTC.
            </p>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.actionButton}>
            <button onClick={() => onConfirm(selectedSkill!)}>Refill</button>
          </div>
          <div className={styles.actionButton}>
            <button onClick={() => onConfirm(selectedSkill!)}>Equip</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryPopupComponent;
