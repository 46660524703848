import React, { useEffect } from "react";
import { images } from "../../constants";
import { usePopup } from "../../modules/popup/popup.provider";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hook";
import InventoryPopupComponent from "../popup/inventory-popup/inventory-popup.component";
import Tab from "../tab";
import InventoryHeroComponent from "./inventory-hero/inventory-hero.component";
import InventoryItem from "./inventory-item";
import styles from "./inventory.module.css";
import {
  AppState,
  reRollInventoryItem,
} from "../../modules/redux/slices/app.slice";

type InventoryProps = {
  items?: AppState["inventories"];
};
const Inventory = ({ items }: InventoryProps) => {
  const tabs = [
    {
      name: "Equipment",
      value: "EQUIPMENT",
    },
    // {
    //   name: "Material",
    //   value: "MATERIAL",
    // },
    // {
    //   name: "Other",
    //   value: "OTHER",
    // },
  ];
  const { hero } = useAppSelector(({ app }) => app);
  const { openPopup, closePopup, isOpen } = usePopup();
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = React.useState<any>();

  useEffect(() => {
    if (!selectedItem) {
      isOpen && closePopup();
      return;
    }
    openPopup(
      <InventoryPopupComponent
        onClose={() => {
          setSelectedItem(null);
        }}
        onReRoll={(e) => {
          dispatch(reRollInventoryItem(e))
            .unwrap()
            .catch(() => {})
            .finally(() => {
              setSelectedItem(null);
            });
        }}
        inventoryItem={selectedItem}
        onConfirm={() => {}}
      />
    );
  }, [selectedItem]);

  const getImageByItemCode = (itemCode: string, type: string) => {
    return (images.items as any)[itemCode] || (images.itemTypes as any)[type];
  };

  return (
    <>
      <InventoryHeroComponent
        items={items}
        hero={hero}
        onItemClick={setSelectedItem}
      />
      <Tab data={tabs} defaultValue={"EQUIPMENT"} onChanged={() => {}} />
      <div className={styles.inventoryItem}>
        {items?.map((item, index) => (
          <InventoryItem
            onClick={() => setSelectedItem(item)}
            item={{
              inventoryId: item.id,
              name: item.name,
              image: getImageByItemCode(item.itemCode, item.name),
              itemType: item.itemType,
            }}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

export default Inventory;
