import { images } from "../../constants";
import { Hero, Progress as Proccess } from "../../interfaces";
import Progress from "../progress";
import styles from "./header.module.css";

type Info = Hero &
  Record<string, any> & { result?: Proccess["result"]["leftHero"] };
interface FightHeaderProps {
  heroLeft: Info;
  heroRight: Info;
}

const FightHeader = ({ heroLeft, heroRight }: FightHeaderProps) => {
  return (
    <>
      {[heroLeft, heroRight].map((hero, index) => {
        return (
          <div className={styles.heroTopContainer}>
            <div
              key={index}
              className={`${styles.hero} ${
                index === 0 ? styles.heroLeft : styles.heroRight
              }`}
            >
              {
                {
                  0: (
                    <>
                      <div className={styles.heroInfo}>
                        <p>
                          {hero.firstName} {hero.lastName}
                        </p>
                        <div className={styles.heroRank}>
                          <p>{hero.attributes.GAME_PROFILE_LEVEL.level}</p>
                          <img
                            src={
                              "https://staggering.tonkombat.com/assets/champion-D65sUMQF.webp"
                            }
                            alt='Champion Icon'
                            className={styles.imgSmall}
                          />
                        </div>
                      </div>
                      <div className={styles.heroImage}>
                        <img
                          className={styles.heroImage}
                          src={hero.avatar || images.system.user}
                          alt='Champion Icon'
                        />
                      </div>
                    </>
                  ),
                  1: (
                    <>
                      <div className={styles.heroImage}>
                        <img
                          className={styles.heroImage}
                          src={hero.avatar || images.system.user}
                          alt='Champion Icon'
                        />
                      </div>
                      <div className={styles.heroInfo}>
                        <p>
                          {hero.firstName} {hero.lastName}
                        </p>
                        <div className={styles.heroRank}>
                          <img
                            src={
                              "https://staggering.tonkombat.com/assets/champion-D65sUMQF.webp"
                            }
                            alt='Champion Icon'
                            className={styles.imgSmall}
                          />
                          <p>{hero.totalLevel}</p>
                        </div>
                      </div>
                    </>
                  ),
                }[index]
              }
            </div>
            <Progress
              rotate={index === 0 ? 180 : 0}
              total={100}
              value={Math.round(
                ((hero.result?.hpPoint ?? 0) / hero.hp.point) * 100,
              )}
            />
          </div>
        );
      })}
    </>
  );
};

export default FightHeader;
