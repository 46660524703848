import { Draw, SingleAnimationComponentProps } from "./animation.interface";

export const animation = ({
  src,
  width,
  height,
  totalFrame,
  lineFrame,
  lineOffset,
  frameOffset,
  flip,
}: SingleAnimationComponentProps) => {
  const isDebug = false;
  const logger = (...args: any[]) => {
    isDebug && console.log(...args);
  };
  let isReady = false;
  const buildImage = () => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      logger("image size", img.width, img.height);
      isReady = true;
    };
    return img;
  };
  const img = buildImage();

  const drawWithFlip = (ctx: Draw["ctx"]) => {
    return (
      image: CanvasImageSource,
      sx: number,
      sy: number,
      sw: number,
      sh: number,
      dx: number,
      dy: number,
      dw: number,
      dh: number
    ) => {
      if (flip) {
        dx = -dx;
        dw = -dw;
        ctx.scale(-1, 1);
        ctx.drawImage(image, sx, sy, sw, sh, dx, dy, dw, dh);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      } else ctx.drawImage(image, sx, sy, sw, sh, dx, dy, dw, dh);
    };
  };

  let currentFrame = 0;
  const draw = ({
    ctx,
    location = { x: 0, y: 0 },
    sizeRatio = 1,
    srcImage,
  }: Draw) => {
    const { x: destX, y: destY } = location;
    if (!isReady) {
      return;
    }
    img.src = srcImage || src;

    const globalCurrentFrame = currentFrame + frameOffset;
    const lineIndex = Math.floor(globalCurrentFrame / lineFrame) + lineOffset;
    const columnIndex = Math.floor(globalCurrentFrame % lineFrame);

    const x = columnIndex * width;
    const y = lineIndex * height;
    const destWidth = width * sizeRatio;
    const destHeight = height * sizeRatio;
    drawWithFlip(ctx)(
      img,
      x,
      y,
      width,
      height,
      destX,
      destY,
      destWidth,
      destHeight
    );
    currentFrame++;
    if (currentFrame >= totalFrame) {
      currentFrame = 0;
    }
  };

  return draw;
};
