import { first, get } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FightHeader from "../../components/fight/header";
import MatchComponent, {
  MatchComponentRef,
} from "../../components/match/match.component";
import Progress from "../../components/progress";
import Tab from "../../components/tab";
import { images } from "../../constants";
import { Match, Progress as Process } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hook";
import {
  requestGameProfile,
  resetMatch,
} from "../../modules/redux/slices/app.slice";
import styles from "./fight.module.css";

const FightPage = () => {
  const navigate = useNavigate();
  const [match, setMatch] = useState<Match>();
  const { matchResult } = useAppSelector(({ app }) => app);
  const matchComponentRef = useRef<MatchComponentRef>();
  const dispatch = useAppDispatch();

  const canvaContainerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [turn, setTurn] = useState("");
  const [process, setProcess] = useState<Process>();

  const [stage, setStage] = useState<"FINISHED" | "IN_PROGRESS">();

  const tabs = [
    {
      name: "Default",
      value: "DEFAULT",
    },
    {
      name: "Detailed",
      value: "DETAILED",
    },
  ];

  useEffect(() => {
    const updateDimensions = () => {
      if (canvaContainerRef.current) {
        const { offsetWidth, offsetHeight } = canvaContainerRef.current;
        setDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    if (!matchResult) {
      toast("You are not in a match");
      navigate("/kombat");
    }
    setMatch(matchResult);

    return () => {
      dispatch(resetMatch());
    };
  }, []);

  useEffect(() => {
    if (!matchResult!.progress?.length || !dimensions.height) return;
    (async () => {
      if (!matchComponentRef.current) return;
      setProcess(first(matchResult!.progress));
      console.log("waiting for 3 seconds");
      let inx = 3;
      await new Promise((resolve) =>
        setInterval(() => {
          if (inx > 0) setTurn(`Waiting ${inx} seconds`);
          else if (inx === 0) {
            setTurn(`Fight`);
            resolve(true);
          }
          inx--;
        }, 1000),
      );

      console.log("actual fight");
      let index = 0;
      for (const process of matchResult!.progress) {
        index += 1;
        if (process.turnOf === "NONE") {
          // wait to initialize
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          setTurn("Stage " + process.globalTurnNumber);
          matchComponentRef.current?.onfight({
            fightState:
              process.turnOf === "LEFT" ? "LEFT_ATTACK" : "RIGHT_ATTACK",
            process,
          });

          await new Promise((resolve) => setTimeout(resolve, 2000));
          if (
            process.result.leftHero.isDead ||
            process.result.rightHero.isDead
          ) {
            setTurn(
              "You" + (process.result.leftHero.isDead ? " lost" : " won"),
            );
          }
        }

        setStage(
          index === matchResult!.progress.length ? "FINISHED" : "IN_PROGRESS",
        );
        setProcess(process);
      }
    })();
  }, [dimensions, matchResult!.progress]);

  const frames = useMemo(() => {
    if (!dimensions.height || !dimensions.width) return;

    return (
      <>
        {matchResult && (
          <MatchComponent ref={matchComponentRef} dimensions={dimensions} />
        )}
      </>
    );
  }, [matchResult, dimensions]);

  const round = useMemo(() => {
    return <div className={styles.round}>{turn}</div>;
  }, [turn]);

  const formatString = (str: string) => {
    const words = str.split(" ");
    words[0] =
      words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    return words.join(" ");
  };

  const { heroTop, stats } = useMemo(() => {
    if (!match) return {};
    const {
      initData: {
        leftHeroes,
        rightHeroes,
        leftFullGameProfile,
        rightFullGameProfile,
        rightUserProfile,
        leftUserProfile,
      },
    } = match!;

    const heroLeft = {
      ...leftHeroes[0],
      ...leftFullGameProfile,
      ...leftUserProfile,
      result: process?.result.leftHero,
    };
    const heroRight = {
      ...rightHeroes[0],
      ...rightFullGameProfile,
      ...rightUserProfile,
      result: process?.result.rightHero,
    };

    const statsByHero = (
      hero: any,
      type: string | "attack" | "hp" | "evasion" | "skill",
      side: "LEFT" | "RIGHT",
      maximumSteps: number = 0,
    ) => {
      if (type === "skill") {
        return {
          LEFT: (
            <div className={`${styles.statsItem} ${styles.skill}`}>
              <img
                src={get(images, ["skills", hero[type]])}
                alt={hero[type]}
                className={styles.imgSmall}
              />
              <p>
                <span>{formatString(hero[type])}</span>&nbsp;
              </p>
            </div>
          ),
          RIGHT: (
            <div className={`${styles.statsItem} ${styles.skill}`}>
              <p>
                <span>{formatString(hero[type])}</span>&nbsp;
              </p>
              <img
                src={get(images, ["skills", hero[type]])}
                alt={hero[type]}
                className={styles.imgSmall}
              />
            </div>
          ),
        }[side];
      }
      return (
        <div className={styles.statsItem}>
          <p
            style={
              side === "LEFT" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            {side === "LEFT" ? (
              <>
                <span>{hero[type]?.point}</span>&nbsp;{formatString(type)}
              </>
            ) : (
              <>
                {formatString(type)}&nbsp;<span>{hero[type]?.point}</span>
              </>
            )}
          </p>
          <div
            className='process'
            style={
              side === "LEFT"
                ? {
                    transform: "rotateY(180deg) rotateX(180deg)",
                  }
                : {}
            }
          >
            <Progress
              total={100}
              value={((hero[type]?.point || 0) / (maximumSteps || 1)) * 100}
            />
          </div>
        </div>
      );
    };

    return {
      heroTop: <FightHeader heroLeft={heroLeft} heroRight={heroRight} />,
      stats: (
        <>
          {[
            { code: "attack" },
            { code: "hp" },
            { code: "evasion" },
            { code: "skill" },
          ].map((item, index) => (
            <div key={index} className={styles.statsItemLine}>
              <div className={styles.statsLeft}>
                {statsByHero(
                  heroLeft,
                  item.code,
                  "LEFT",
                  Math.round(
                    Math.max(
                      get(heroLeft, [item.code, "point"], 0),
                      get(heroRight, [item.code, "point"], 0),
                    ) / 1000,
                  ) * 1000,
                )}
              </div>
              <div className={styles.statsCenter}>
                <img
                  src={get(images, item.code.toLowerCase())}
                  alt={item.code}
                />
              </div>
              <div className={styles.statsRight}>
                {statsByHero(
                  heroRight,
                  item.code,
                  "RIGHT",
                  Math.round(
                    Math.max(
                      get(heroLeft, [item.code, "point"], 0),
                      get(heroRight, [item.code, "point"], 0),
                    ) / 1000,
                  ) * 1000,
                )}
              </div>
            </div>
          ))}
        </>
      ),
    };
  }, [match, process]);

  const onNext = () => {
    navigate("/kombat");
    dispatch(resetMatch());
    dispatch(requestGameProfile());
  };

  const actionButton = useMemo(() => {
    return (
      <div className={styles.action}>
        {stage === "FINISHED" && (
          <>
            <div className={`${styles.btn} ${styles.shareButton}`}>
              <button>
                <img src={images.system.share} alt='Share' />
                Share
              </button>
            </div>
            <div className={`${styles.btn} ${styles.nextButton}`}>
              <button onClick={onNext}>Next</button>
            </div>
          </>
        )}
      </div>
    );
  }, [stage, onNext]);

  return (
    <div className={styles.container}>
      <div className={styles.heroTop}>{heroTop}</div>
      <div className={styles.stage}>
        <div className={styles.mainAction}>
          {[
            { icon: images.info },
            {
              icon: images.system.arrow.right,
            },
          ].map((item, index) => (
            <div key={index} className={`${styles.actionContent}`}>
              <button type='button' className={styles.button}>
                <img src={item.icon} alt='arrow' />
              </button>
            </div>
          ))}
        </div>
        {round}
      </div>
      <div className={styles.main}>
        <div ref={canvaContainerRef} className={styles.mainCharacters}>
          {frames}
        </div>
      </div>
      <div className={styles.tabs}>
        <Tab data={tabs} defaultValue={tabs[0].value} />
      </div>
      <div className={styles.stats}>{stats}</div>
      {actionButton}
    </div>
  );
};

export default FightPage;
