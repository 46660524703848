import styles from "./progress.module.css";

type ProgressProps = {
  value?: number;
  total?: number;
  rotate?: number;
};

const Progress = ({ value = 20, total = 100, rotate }: ProgressProps) => {
  // Ensure value and total are positive integers
  const validValue = Math.round(Math.max(Number(value) || 0, 0));
  const validTotal = Math.round(Math.max(Number(total) || 0, 0));

  if (validTotal === 0) {
    return null;
  }

  return (
    <div
      style={{
        rotate: `${rotate || 0}deg`,
      }}
      className={styles.progress}
      id={`${validTotal}_${validValue}`}
    >
      {Array(Math.max(validValue, validTotal))
        .fill(0)
        .map((_, index) => {
          return (
            <div
              key={index}
              id={String(index + 1)}
              className={`${styles.step} ${
                index + 1 <= validValue ? styles.complete : ""
              }`}
            ></div>
          );
        })}
    </div>
  );
};

export default Progress;
