import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export const numberToRoman = (num: number): string => {
    // Define the Roman numeral mappings
    const romanNumerals = [
        { value: 1000, numeral: 'M' },
        { value: 900, numeral: 'CM' },
        { value: 500, numeral: 'D' },
        { value: 400, numeral: 'CD' },
        { value: 100, numeral: 'C' },
        { value: 90, numeral: 'XC' },
        { value: 50, numeral: 'L' },
        { value: 40, numeral: 'XL' },
        { value: 10, numeral: 'X' },
        { value: 9, numeral: 'IX' },
        { value: 5, numeral: 'V' },
        { value: 4, numeral: 'IV' },
        { value: 1, numeral: 'I' }
    ];

    let result = '';

    // Loop through each Roman numeral value
    for (let i = 0; i < romanNumerals.length; i++) {
        // While the number is greater than or equal to the current Roman numeral value
        while (num >= romanNumerals[i].value) {
            result += romanNumerals[i].numeral;
            num -= romanNumerals[i].value;
        }
    }

    return result;
}

export const dateDiff = (targetDateStr: Date) => {
    const targetDate = dayjs(targetDateStr);
    const currentDate = dayjs();

    if (targetDate.isBefore(currentDate)) {
        return;
    }

    // Calculate the difference
    const duration = targetDate.diff(currentDate);

    // Extract the hours, minutes, and seconds from the duration
    const hours = dayjs.duration(duration).hours();
    const minutes = dayjs.duration(duration).minutes();
    const seconds = dayjs.duration(duration).seconds();

    // Format the countdown string
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0",
    )}:${String(seconds).padStart(2, "0")}`;
};
